.import-export-modal {
  &__content {
    width: 600px;
  }

  &__field {
    margin-bottom: 12px;
  }

  &__footer {
    margin: 0 !important;
    padding: 0 24px 24px
  }

  .company-settings {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}

.import-export__actions {
  gap: 12px;
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;
  justify-content: end;
}
