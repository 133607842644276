@use '../../../../sass/0_settings/variables' as vars;

.offer-map-info {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__offer-icons {
    font-size: 18px;
    margin-bottom: 5px;
  }

  &__offer-gray-icons {
    .icon {
      color: vars.$color-grey-07;
    }
  }

  &__offer-cancelation-icon {
    display: inline-block;
    margin-right: 3px;
    position: relative;
    top: -2px;

    .icon {
      font-size: 18px;
    }
  }

  &__description {
    line-height: 1.4;
    margin-bottom: 10px;
    margin-top: 10px;
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    &-label {
      text-transform: uppercase;
    }
  }

  &__description-content {
    flex-grow: 1;
  }

  &__header {
    font-size: 16px;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-title {
      font-size: 20px;
    }

    &-stars {
      font-size: 20px;
    }
  }

  &__address {
    margin-bottom: 15px;
    font-size: 12px;

    &:before {
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 16px;
      content: "\e97c";
      color: vars.$color-google;
      margin-right: 1px;
      position: relative;
      top: 2px;
    }
  }

  &__amount {
    display: flex;
    align-items: center;
    justify-content: end;
    font-size: 18px;
    margin-top: auto;

    .selected-hotel__price-icon {
      font-size: 18px;
    }
  }

  &__offer-corporate-rate {
    display: inline-block;
    margin-right: 3px;
  }

}
